<template>
  <div class="info-section">
    <p v-if="mobileScreen" class="info-section__title">Please note</p>
    <p v-if="mobileScreen" class="info-section__copy">Our new website is currently being designed, however, we are always happy to help.</p>
    <div>

      <div class="info-section__phone">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.6 25.6">
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path d="M17.2,2,10.1,7.9H10L2.9,13.9c-6.7,5.6.3,11.7.3,11.7l6.9-5.7a8.5,8.5,0,0,0-3.4-4.7l5.5-4.6h.1l5.5-4.7a8.5,8.5,0,0,0,4,4.2l6.8-5.7S23.9-3.6,17.2,2Z" fill="#b9d0e7"/>
            </g>
          </g>
        </svg>
        <a href="tel:01460394007">01460 394 007</a>
        <span class="bull">
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="50" fill="#b6d1df" />
          </svg>
        </span>
        <a href="tel:07534970786">07534 970 786</a>
      </div>

      <div class="info-section__contact info-section__contact--email">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.9 24.8">
          <title>Email</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <g>
                <polyline points="0 21.9 15.6 12.2 0 2.1" fill="#b9d0e7"/>
                <path d="M35.6,0H1.1A1.2,1.2,0,0,0,0,1.3L18.9,13.6,37.8,1.5S37.7,0,35.6,0Z" fill="#b9d0e7"/>
                <path d="M35.6,24.8H1.8A1.9,1.9,0,0,1,.1,22.6H.2L16,12.7h.1L19,14.3l1.5-.9,1.2-.7,16.1,10h0A2,2,0,0,1,35.6,24.8Z" fill="#b9d0e7"/>
                <polyline points="37.8 21.9 22.3 12.2 37.9 2.1" fill="#b9d0e7"/>
              </g>
            </g>
          </g>
        </svg>
        <a href="mailto:office@carrarino.co.uk?subject=Web%20message">
          <p>office@carrarino.co.uk</p>
        </a>
         <span class="bull">
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="50" fill="#b6d1df" />
          </svg>
        </span>
        <a href="mailto:paolo@carrarino.co.uk?subject=Web%20message">
          <p>paolo@carrarino.co.uk</p>
        </a>
      </div>

      <div class="info-section__contact info-section__contact--address">
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 15.44 24.18">
          <g id="Layer_1-2" data-name="Layer 1">
            <path d="M7.73,0h-.01S-.8-.16.06,8.13c0,0,.66,5.6,7.66,16.05l.02-.03c6.99-10.44,7.64-16.02,7.64-16.02C16.22-.28,7.73,0,7.73,0ZM7.8,11.43c-2.39,0-4.33-1.94-4.33-4.33s1.94-4.33,4.33-4.33,4.33,1.94,4.33,4.33-1.94,4.33-4.33,4.33Z" fill="#b9d0e7"/>
          </g>
        </svg>
        <p>Ilminster Somerset TA19 0JA</p>
      </div>

      <div class="info-section__contact info-section__contact--what3words">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 146.2725 146.2725">
          <g id="Layer_2" data-name="Layer 2">
            <g id="artwork">
              <path d="M117.0206,29.2522H29.2549v87.7656h87.7657V29.2522Z" fill="#ffffff"/>
              <path d="M67.6525,92.3346a2.7455,2.7455,0,0,1-2.602-3.61L76.0212,55.812a2.7429,2.7429,0,0,1,5.2041,1.7356L70.2546,90.46A2.7429,2.7429,0,0,1,67.6525,92.3346Z" fill="#e11f26"/>
              <path d="M51.1965,92.3346a2.7456,2.7456,0,0,1-2.6021-3.61L59.5651,55.812a2.743,2.743,0,0,1,5.2042,1.7356L53.7985,90.46A2.7429,2.7429,0,0,1,51.1965,92.3346Z" fill="#e11f26"/>
              <path d="M84.1086,92.3346a2.7456,2.7456,0,0,1-2.6021-3.61L92.4772,55.812a2.743,2.743,0,0,1,5.2042,1.7356L86.7107,90.46A2.743,2.743,0,0,1,84.1086,92.3346Z" fill="#e11f26"/>
              <rect width="146.2725" height="146.2725" fill="none"/>
            </g>
          </g>
        </svg>
        <a href="https://w3w.co/fills.divisible.bulges" target="_blank">
          <p>fills.divisible.bulges</p>
        </a>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'infoSecton',
  props: {
    mobileScreen: Boolean
  }
}
</script>

<style scoped lang="scss">
  @use "../base.scss" as *;

  .info-section {
    padding: 45px 15px;

    @include med {
      max-width: 90%;
      margin: 0 auto;
    }

    @include lrg {
      position: absolute;
      top: 1%;
      right: 1%;
      background-color: $color-darkblue-opacity;
      padding: 30px;
      @include flex;
      @include flex-justify-center;
      @include flex-align-center;
    }

    &__title {
      color: $color-lightblue;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
      margin-top: 0;
      text-align: center;
    }
    // @media (min-width: 700px) {
    //         font-size: 24px;
    //     }

    &__copy {
      font-size: 11px;
      font-weight: 300;
      text-align: center;
      width: 100%;
      max-width: 310px;
      line-height: 20px;
      margin: 0 auto;

      @include sml {
        font-size: 13px;
        max-width: 350px;
      }

      @include med {
        font-size: 15px;
        max-width: 400px;
      }

    }
    // @media (min-width: 700px) {
    //         font-size: 16px;
    //         width: 500px;
    //         line-height: 26px;
    //     }

    &__phone {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 10px;

      svg {
        width: 25px;
        margin: 0px 15px 0px 0px;
      }
      a {
        text-decoration: none;
        color: #fff;
        font-size: 15px;

        &:hover {
          color: $color-lightblue;
        }
      }
      span.bull {
        svg {
          width: 5px;
          margin: 0 15px;
        }
      }
    }

    &__contact {
      display: flex;
      align-items: center;
      margin-top: 30px;
      flex-wrap: wrap;
      row-gap: 10px;

      svg {
        width: 25px;
        margin: 0px 15px 0px 0px;
      }

      &--address {
        margin-top: 30px;

        svg {
          width: 20px;
          margin: 0px 20px 0px 0px;
        }
      }

      &--what3words {
        margin-top: 25px;

        svg {
          width: 35px;
          margin: 0px 10px 0px -5px;
        }
      }

      &--email {
        svg {
          top: 1px;
        }
      }
      p {
        display: inline-block;
        font-size: 16px;
        font-weight: 300;
        padding: 0;
        margin: 0;
      }
      a {
        color: #fff;
        display: flex;
        align-items: center;
        text-decoration: none;

        &:hover {
          color: $color-lightblue;
        }

        p{
        }
      }
      span.bull {
        svg {
          width: 5px;
          margin: 0 15px;
        }
      }
    }
  }
</style>
